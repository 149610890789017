import React, { useState } from 'react';
import { useMsal } from "@azure/msal-react";
import { IonLoading, IonButton, IonItem, IonInput, IonCard, IonGrid, IonRow, IonCol, IonIcon, IonModal, IonToolbar, IonContent, IonTitle } from '@ionic/react';
import getCustomerList from '../../Components/Fetch/getCustomerList';
import { searchSharp } from 'ionicons/icons';
import { Customer } from '../../Interfaces/Customer';

interface CustomerSearchProps {
    isModal?: boolean
    selectionCallback?: callback;
}

interface callback {
    (customer: Customer): void
}

const CustomerSearch = ({ isModal, selectionCallback }: CustomerSearchProps) => {
    const { instance } = useMsal();
    const [customerInput, setCustomerInput] = useState("");
    const [customers, setCustomers] = useState([] as Array<Customer>);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [detailCustomer, setDetailCustomer] = useState({} as Customer);
    const [showDetailModal, setShowDetailModal] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState("");

    const handleCustLookup = () => {
        if (!loading) { //prevents this from running multiple times (clickling button and blur trigger same time)
            if (customerInput !== "") {
                setLoading(true);
                getCustomerList(instance, customerInput).then(data => {
                    setLoading(false);
                    if (data !== null && data.length > 0) {
                        setCustomers(data);
                        if (isModal) {
                            setShowModal(true);
                        }
                    } else {
                        setCustomers([]);
                        setMessage("No Customers Found");
                        setShowMessage(true);
                    }

                })
            } else {
                //customer Input is empty clear any data
                setCustomers([]);
            }
        }
    }

    const getCustomers = () => customers.map((customer, index) => {
        return (
            <IonCard id={`customer-${index}`} key={`customer-${index}`} onClick={
                () => {
                    if (isModal) {
                        setShowModal(false);
                    }
                    if (selectionCallback) {
                        selectionCallback(customer);
                    }
                }}>
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            {!isModal && <IonButton className="floatRight" onClick={() => {
                                setDetailCustomer(customer);
                                setShowDetailModal(true);
                            }}>...</IonButton>}
                            {customer.description !== null && <div><b>{customer.description}</b> ({customer.customerNumber})</div>}
                            {customer.addressOne !== null && <div>{customer.addressOne}</div>}
                            {customer.addressTwo !== null && <div>{customer.addressTwo}</div>}
                            {customer.addressThree !== null && <div>{customer.addressThree}</div>}
                            {customer.city !== null
                                && customer.state !== null
                                && customer.zip !== null
                                && <div>{customer.city},{customer.state} {customer.zip}</div>}
                            {!isModal && customer.contact !== null && <div>{customer.contact}</div>}
                            {!isModal && customer.phone !== null && <div><a href={`tel:${customer.phone}`}>{customer.phone}</a></div>}
                            {!isModal && customer.emailAddress !== null && <div><a href={`mailto:${customer.emailAddress}`}>{customer.emailAddress}</a></div>}
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonCard>
        );
    });

    return (
        <div>
            <IonLoading isOpen={loading} />
            <IonLoading isOpen={showMessage} spinner={null} message={message} backdropDismiss onDidDismiss={() => { setShowMessage(false); setMessage(""); }} />

            <div className='form-field'>
                <IonGrid>
                    <IonRow>
                        <IonCol size="9">
                            <IonItem>
                                <IonInput value={customerInput} placeholder="Customer Name" onIonBlur={handleCustLookup} onIonInput={e => {
                                    setCustomerInput(e.detail.value!)
                                }} clearInput></IonInput>
                            </IonItem>
                        </IonCol>
                        <IonCol size="3">
                            <IonButton expand="block" onClick={handleCustLookup}>
                                <IonIcon icon={searchSharp} />
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </div>
            {isModal && <IonModal isOpen={showModal}>
                <IonToolbar>
                    <IonTitle>Customer Lookup</IonTitle>
                    <IonButton className="floatRight" onClick={() => { setShowModal(false) }}>x</IonButton>
                </IonToolbar>
                <IonContent fullscreen>
                    {getCustomers()}
                </IonContent>
            </IonModal>}
            {!isModal && <div>
                {getCustomers()}
                <IonModal isOpen={showDetailModal}>
                    <IonToolbar>
                        <IonTitle>{detailCustomer.description}</IonTitle>
                        <IonButton className="floatRight" onClick={() => { setShowDetailModal(false); setDetailCustomer({} as Customer); }}>x</IonButton>
                    </IonToolbar>
                    <IonContent fullscreen>
                        {detailCustomer?.shipTos?.shipToAddress
                            ?.filter(a => a.shipToName !== null).map((address, index) => <IonCard id={`shippingAddress${index}`} key={`shippingAddress${index}`}>
                                <IonGrid>
                                    <IonRow>
                                        <IonCol size="12">
                                            {address.shipToName !== null && <div><b>{address.shipToName}</b></div>}
                                            {address.addressLine1 !== null && <div>{address.addressLine1}</div>}
                                            {address.addressLine2 !== null && <div>{address.addressLine2}</div>}
                                            {address.addressLine3 !== null && <div>{address.addressLine3}</div>}
                                            {address.city !== null
                                                && address.state !== null
                                                && address.zip !== null
                                                && <div>{address.city},{address.state} {address.zip}</div>}
                                            {address.contact !== null && <div>{address.contact}</div>}
                                            {address.phone !== null && <div><a href={`tel:${address.phone}`}>{address.phone}</a></div>}
                                            {address.emailAddress !== null && (
                                                <div>
                                                    {address.emailAddress.split(',').map((email, index) => (
                                                        <span key = { index }>
                                                            <a href={`mailto:${email.trim()}`}>{email.trim()}</a>
                                                            <br />
                                                        </span>
                                                    ))}
                                                </div>
                                            )}
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </IonCard>
                            )}
                    </IonContent>
                </IonModal>
            </div>}
        </div>
    );
}

export default CustomerSearch;