import React, { useState, useEffect, useRef } from 'react';
import CustomerSearch from '../../Components/CustomerSearch'
import { IonButton, IonCard, IonCol, IonGrid, IonIcon, IonInput, IonItem, IonLabel, IonRow, IonSelect, IonSelectOption, IonLoading, IonTextarea, IonSegment, IonSegmentButton, IonText} from '@ionic/react';
import { trashOutline } from 'ionicons/icons';
import { LineItemDetail } from '../../Interfaces/Order';
import { Customer } from '../../Interfaces/Customer';
import { Warehouse } from '../../Interfaces/Warehouse';
import { useMsal } from "@azure/msal-react";
import { CreateQuoteRequest } from '../../Interfaces/Quote';

import getInsideSalesEmails from '../../Components/Fetch/getInsideSalesEmails';
import getWarehouses from '../../Components/Fetch/getWarehouses';
import postQuote from '../../Components/Fetch/postQuote';

const Quote = () => {
    const [selectedCustomer, setSelectedCustomer] = useState({} as Customer)
    const [selectedShipToIndex, setSelectedShipToIndex] = useState(0);
    const [selectedWarehouse, setSelectedWarehouse] = useState("");
    const [newProductNumber, setNewProductNumber] = useState("");
    const [newProductNumberQty, setNewProductNumberQty] = useState<number | null>(null);
    const [selectedProducts, setSelectedProducts] = useState([] as Array<LineItemDetail>)
    const [warehouses, setWarehouses] = useState([] as Array<Warehouse>)
    const [selectedSalesRep, setSelectedSalesRep] = useState("");
    const [insideSalesEmails, setInsideSalesEmails] = useState([] as Array<string>)
    const [poNumber, setPoNumber] = useState("");
    const [email, setEmail] = useState<string>("");
    const [phone, setPhone] = useState<string>("");
    const [contactName, setContactName] = useState<string>("");
    const [customerJobNumber, setCustomerJobNumber] = useState("");
    const [showLoading, setShowLoading] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState("");
    const [scannerMode, setScannerMode] = useState(localStorage.getItem("preferScannerMode") || "true");

    const { instance } = useMsal();

    const regexEmailPattern = /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(,\s*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})*$/;
    const regexPhonePattern = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

    const isDisabled = selectedProducts.length <= 0 //has added product
        || selectedWarehouse === "" //has a warehouse selected
        || contactName === ""
        || (typeof (phone) === "string" && phone !== "" && phone.match(regexPhonePattern) === null) //if phone, must match pattern
        || (typeof (email) === "string" && email !== "" && email.match(regexEmailPattern) === null) //if email, must match pattern
        || selectedSalesRep === "";

    const productInput = useRef<HTMLIonInputElement>(null);
    const qtyInput = useRef<HTMLIonInputElement>(null);

    //populate available warehouses
    useEffect(() => {
        let isMounted = true; //track component mount state

        const fetchData = async () => {
            let availWarehouses = await getWarehouses(instance);
            let availSalesReps = await getInsideSalesEmails(instance);
            if (isMounted) {
                setWarehouses(availWarehouses);
                setInsideSalesEmails(availSalesReps);
            }
        };

        fetchData();

        // Cleanup function called when component is unmounted
        return () => {
            isMounted = false;
        };
    }, [instance]);

    useEffect(() => {
        let isMounted = true; //track component mount state
        if (selectedCustomer?.shipTos?.shipToAddress?.length > 0) {
            let tmpContact = selectedCustomer.shipTos.shipToAddress[selectedShipToIndex]?.contact;
            let tmpEmail = selectedCustomer.shipTos.shipToAddress[selectedShipToIndex]?.emailAddress;
            let tmpPhone = selectedCustomer.shipTos.shipToAddress[selectedShipToIndex]?.phone;

            setContactName( tmpContact ? tmpContact : selectedCustomer?.contact);
            setEmail( tmpEmail ? tmpEmail : selectedCustomer?.emailAddress);
            setPhone( tmpPhone ? tmpPhone : selectedCustomer?.phone);
        }
        // Cleanup function called when component is unmounted
        return () => {
            isMounted = false;
        };
    }, [selectedShipToIndex]);

    const clearForm = () => {
        setSelectedCustomer({} as Customer);
        setSelectedProducts([]);
        setSelectedShipToIndex(0);
        setSelectedSalesRep("");
        setPoNumber("");
        setSelectedWarehouse("");
        setCustomerJobNumber("");
        setEmail("");
        setPhone("");
        setContactName("");
    }

    const addProduct = (q = newProductNumberQty || null) => {
        if (newProductNumber && q !== null && q > 0) {
            let tmpProducts = selectedProducts;

            tmpProducts.push({
                productNumber: newProductNumber,
                quantity: q.toString()
            });

            setSelectedProducts(tmpProducts);

            setNewProductNumber("");
            setNewProductNumberQty(null);

            if (scannerMode === "true" && productInput.current) {
                productInput.current.setFocus();
            }
        }
    };

    return (<div>
        <IonLoading isOpen={showLoading} message="<strong>Submitting Quote</strong><br />This could take up to 30 seconds." />
        <IonLoading isOpen={showMessage} spinner={null} message={message} backdropDismiss onDidDismiss={() => { setShowMessage(false); setMessage(""); }} />
        <CustomerSearch isModal={true} selectionCallback={(result) => {
            clearForm();
            setSelectedCustomer(result);
            setPhone(result.phone);
            setEmail(result.emailAddress);
            setContactName(result.contact);
        }} />
        <IonGrid>
            <IonRow>
                <IonCol>
                    {selectedCustomer.description !== null && <div><b>{selectedCustomer.description}</b></div>}
                    {selectedCustomer.contact !== null && <div>{selectedCustomer.contact}</div>}
                    {selectedCustomer.phone !== null && <div><a href={`tel:${selectedCustomer.phone}`}>{selectedCustomer.phone}</a></div>}
                    {selectedCustomer.emailAddress !== null && <div><a href={`mailto:${selectedCustomer.emailAddress}`}>{selectedCustomer.emailAddress}</a></div>}
                </IonCol>
            </IonRow>
            {selectedCustomer.customerNumber && <IonRow>
                <IonCol>
                    <IonLabel>Shipping Address <span className='required'>*</span></IonLabel>
                    {selectedCustomer?.shipTos?.shipToAddress
                        ?.filter(a => a.shipToName !== null).map((address, index) => <IonCard id={`shippingAddress${index}`} key={`shippingAddress${index}`} className={index === selectedShipToIndex ? "selectedCard" : ""} onClick={() => setSelectedShipToIndex(index)}>
                            <IonGrid>
                                <IonRow>
                                    <IonCol size="12">
                                        {address.shipToName !== null && <div><b>{address.shipToName}</b></div>}
                                        {address.addressLine1 !== null && <div>{address.addressLine1}</div>}
                                        {address.addressLine2 !== null && <div>{address.addressLine2}</div>}
                                        {address.addressLine3 !== null && <div>{address.addressLine3}</div>}
                                        {address.city !== null
                                            && address.state !== null
                                            && address.zip !== null
                                            && <div>{address.city},{address.state} {address.zip}</div>}

                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        </IonCard>
                        )}
                </IonCol>
            </IonRow>}
            {selectedCustomer.customerNumber && <IonRow>
                <IonCol>
                    <IonLabel>Shipping Contact <span className='required'>*</span></IonLabel>
                    <IonRow>
                        <IonCol size="12">
                            <IonItem>
                                <IonInput type="text" value={contactName} debounce={500} placeholder="Contact Name"
                                    onIonInput={e => {
                                        let nameText = e.detail.value!;
                                        setContactName(nameText);
                                    }} clearInput></IonInput>
                            </IonItem>
                            <IonText color="danger" hidden={contactName !== ""}><i>*Invalid Name</i></IonText>
                            <IonItem>
                                <IonInput type="tel" value={phone} debounce={500} placeholder="Phone Number"
                                    onIonInput={e => {
                                        let phoneText = e.detail.value!;
                                        setPhone(phoneText);
                                    }} clearInput></IonInput>
                            </IonItem>
                            <IonText color="danger" hidden={!phone || phone.match(regexPhonePattern) !== null}><i>*Invalid Number</i></IonText>
                            <IonItem>
                                <IonInput type="email" value={email} debounce={500} placeholder="Email Address"
                                    onIonInput={e => {
                                        let emailText = e.detail.value!;
                                        setEmail(emailText);
                                    }} clearInput></IonInput>
                            </IonItem>
                            <IonText color="danger" hidden={!email || email.match(regexEmailPattern) !== null}><i>*Invalid Email</i></IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="12">
                            
                        </IonCol>
                       {/* <IonCol size="2">
                            <a href={`tel:${phone}`}><IonButton color="primary" expand="block">Call</IonButton></a>
                        </IonCol>*/}
                    </IonRow>
                    <IonRow>
                        <IonCol size="12">
                            
                        </IonCol>
                        {/*<IonCol size="2">
                            <a href={`mailto:${email}`}><IonButton color="primary" expand="block">Mail</IonButton></a>
                        </IonCol>*/}
                    </IonRow>
                </IonCol>
            </IonRow>}
            <IonRow>
                <IonCol>
                    {selectedCustomer.customerNumber && <IonLabel>Warehouse <span className='required'>*</span></IonLabel>}
                    {selectedCustomer.customerNumber && <IonItem>
                        <IonSelect placeholder="Select One" onIonChange={e => setSelectedWarehouse(e.detail.value)}>
                            {warehouses.map((warehouse, index) => <IonSelectOption id={`warehouse${index}`} key={`warehouse${index}`} value={warehouse.code}>{warehouse.name}</IonSelectOption>)}
                        </IonSelect>
                    </IonItem>}
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    {selectedCustomer.customerNumber && <IonLabel>PO Number</IonLabel>}
                    {selectedCustomer.customerNumber && <IonItem>
                        <IonInput value={poNumber} placeholder="PO Number" onIonInput={e => {
                            setPoNumber(e.detail.value!)
                        }} clearInput></IonInput>
                    </IonItem>}
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    {selectedCustomer.customerNumber && <IonLabel>Customer Job Number</IonLabel>}
                    {selectedCustomer.customerNumber && <IonItem>
                        <IonInput value={customerJobNumber} maxlength={37} placeholder="Customer Job Number" onIonInput={e => {
                            setCustomerJobNumber(e.detail.value!)
                        }}></IonInput>
                    </IonItem>}
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    {selectedCustomer.customerNumber && <IonLabel>Inside Sales Rep Email <span className='required'>*</span></IonLabel>}
                    {selectedCustomer.customerNumber && <IonItem>
                        <IonSelect placeholder="Select One" onIonChange={e => setSelectedSalesRep(e.detail.value)}>
                            {insideSalesEmails.map((email, index) => <IonSelectOption id={`email${index}`} key={`email${index}`} value={email}>{email}</IonSelectOption>)}
                        </IonSelect>
                    </IonItem>}
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>

                    {selectedCustomer.customerNumber && <IonRow>
                        <IonLabel>Products <span className='required'>*</span></IonLabel>

                        <IonSegment value={scannerMode} onIonChange={e => {
                            setScannerMode(e.detail.value?.toString()!);
                            localStorage.setItem("preferScannerMode", e.detail.value?.toString()!);
                        }}>
                            <IonSegmentButton value="true">
                                <IonLabel>Scan Mode On</IonLabel>
                            </IonSegmentButton>
                            <IonSegmentButton value="false">
                                <IonLabel>Scan Mode Off</IonLabel>
                            </IonSegmentButton>
                        </IonSegment>

                    </IonRow>
                    }
                    {selectedCustomer.customerNumber &&
                        <IonRow>
                            <IonCol size="6">
                                <IonItem>
                                    <IonInput ref={productInput} value={newProductNumber} placeholder="Product" debounce={scannerMode ? 200 : 0} onIonInput={e => {
                                        //conditional logic prevents the debounce from triggering a series of click events when no value is present
                                        setNewProductNumber(e.detail.value!);
                                        if (scannerMode === "true" && e.detail.value !== "" && qtyInput.current) {
                                            qtyInput.current.setFocus();
                                        }
                                    }} clearInput></IonInput>
                                </IonItem>
                            </IonCol>
                            <IonCol size="4">
                                <IonItem>
                                    <IonInput ref={qtyInput} type="number" value={newProductNumberQty} placeholder="Qty" debounce={scannerMode ? 200 : 0} onIonInput={e => {
                                        //conditional logic prevents the debounce from triggering a series of click events when no value is present
                                        let qty = e.detail.value !== null && e.detail.value !== undefined ? parseInt(e.detail.value!, 10) : null;
                      
                                        setNewProductNumberQty(qty);
                                        if (scannerMode === "true" && e.detail.value !== null && e.detail.value !== undefined) {
                                            addProduct(qty);
                                        }

                                    }} clearInput></IonInput>
                                </IonItem>
                            </IonCol>
                            <IonCol size="2">
                                <IonButton color="primary" expand="block" onClick={()=>addProduct()}>+</IonButton>
                            </IonCol>
                        </IonRow>}
                    {selectedCustomer.customerNumber && selectedProducts.map((product, index) => <IonCard id={`product${index}`} key={`product${index}`}>
                        <IonGrid>
                            <IonRow>
                                <IonCol size="6">
                                    {product.productNumber !== null && <div><b>{product.productNumber}</b></div>}
                                    {product.quantity !== null && <div>Qty: {product.quantity}</div>}
                                </IonCol>
                                <IonCol size="6">
                                    <IonButton className="floatRight" color="primary" onClick={() => {
                                        let tmpProducts = selectedProducts.filter((p) => p.productNumber !== product.productNumber);
                                        setSelectedProducts(tmpProducts);
                                    }}><IonIcon icon={trashOutline}></IonIcon></IonButton>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonCard>)}
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    {selectedCustomer.customerNumber && <IonButton color="primary" expand='block' disabled={isDisabled} onClick={() => {
                        setShowLoading(true);

                        let payload = {
                            quote: {
                                headerInformation: {
                                    customerNumber: selectedCustomer.customerNumber,
                                    contactName: contactName,
                                    customerPurchaseOrderNumber: poNumber,
                                    sellingWarehouse: selectedWarehouse,
                                    shipToAddress: selectedCustomer.shipTos.shipToAddress[selectedShipToIndex],
                                    emailAddress: email,
                                    phoneNumber: phone,
                                    customerJobNumber: customerJobNumber
                                },
                                lineItemDetails: { lineItemDetail: selectedProducts }
                            },
                            companyName: selectedCustomer.description,
                            insideSalesEmail: selectedSalesRep
                        } as CreateQuoteRequest;

                        postQuote(instance, payload).then(data => {
                            setShowLoading(false);
                            if (data !== null && data.responseInfo?.quoteNumber) {
                                clearForm();
                                setMessage(`Quote ${data.responseInfo.quoteNumber} Created`);
                            } else {
                                setMessage("An Error Occurred. Please check internet connection and try again.");
                            }
                            setShowMessage(true);
                        })
                    }}>Submit</IonButton>}
                </IonCol>
            </IonRow>
        </IonGrid>
    </div>);
};

export default Quote;